.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
}

.background {
    background: rgba(251, 226, 205, 1);
}

.main-header {
    .main-menu {
        .list-menu {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            li {
                list-style-type: none;

                a {
                    font-family: Comic Story;
                    display: block;
                    padding: 15px 20px;
                    font-size: 18px;
                    font-weight: 400;
                    line-height: 27.2px;
                    text-align: center;
                    color: rgba(135, 77, 17, 1);
                    text-decoration: none;
                }
            }
        }
    }
}

.section-banner {
    padding: 120px 0 60px 0;

    .main-banner {
        .title {
            font-family: Comic Story;
            font-size: 100px;
            font-weight: 400;
            line-height: 1.4;
            letter-spacing: 0.1em;
            text-align: center;
            color: rgba(251, 226, 205, 1);
            -webkit-text-stroke: 6px #874D11;
            margin-bottom: 10px;
        }

        .desc {
            font-family: Comic Story;
            font-size: 40.74px;
            font-weight: 400;
            line-height: 61.56px;
            letter-spacing: 0.2em;
            text-align: center;
            color: #874D11;
            margin-bottom: 40px;
        }

        .box-img {
            text-align: center;

            img {
                width: 300px;
                display: block;
                margin: 0 auto;
            }
        }

        .list-social {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 80%;
            margin: 0 auto;

            .item {
                cursor: pointer;

                img {
                    width: 90px;
                    display: block;
                }
            }
        }
    }
}

.section-chilly {
    padding: 60px 0;

    .main-chilly {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .left {
            width: 50%;

            .box-img {
                img {
                    width: 100%;
                    display: block;
                }
            }
        }

        .right {
            width: 50%;

            .content-chilly {
                text-align: center;

                .title {
                    font-family: Comic Story;
                    font-size: 80px;
                    font-weight: 400;
                    line-height: 1.2;
                    text-align: center;
                    color: #874D11;
                    -webkit-text-stroke: 4px #FBE2CD;
                    margin-bottom: 60px;
                }

                .number {
                    font-family: Comic Story;
                    font-size: 74px;
                    font-weight: 400;
                    line-height: 1.2;
                    text-align: center;
                    color: rgba(251, 226, 205, 1);
                    -webkit-text-stroke: 4px #874D11;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    img {
                        width: 102px;
                        display: block;
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}

.contract-change {
    .row-contract {
        width: 75%;
        margin: 40px auto 40px;
        padding: 10px 55px 10px 15px;
        border: 4px solid #A25B13;
        background: #FBE2CD;
        font-family: Comic Story;
        font-size: 28px;
        font-weight: 400;
        line-height: 42.31px;
        text-align: left;
        color: #874D11;
        border-radius: 10px;
        position: relative;

        span {
            font-family: Comic Story;
            font-size: 28px;
            font-weight: 400;
            line-height: 42.31px;
            text-align: center;
            color: #FBE2CD;
            background: #874D11;
            border-radius: 4px;
            position: absolute;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            padding: 5px 10px;
            cursor: pointer;
        }
    }
}

.section-bought-chilly {
    padding: 60px 0;

    .main-bought-chilly {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .left {
            width: 35%;

            .box-img {
                img {
                    width: 100%;
                }
            }
        }

        .right {
            width: 63%;
            background: #FBE2CD;
            border-radius: 30px;
            height: 100%;

            .content-bougth {
                padding: 50px 20px;

                .desc {
                    font-family: Comic Story;
                    font-size: 37px;
                    font-weight: 400;
                    line-height: 55.91px;
                    text-align: center;
                    color: #874D11;
                    margin-bottom: 40px;
                }

                .gr-button {
                    .buy-chilly {
                        font-family: Comic Story;
                        font-size: 27.72px;
                        font-weight: 400;
                        line-height: 41.88px;
                        text-align: center;
                        color: #FBE2CD;
                        padding: 5px 20px;
                        background: #874D11;
                        border-radius: 10px;
                        border: 0px solid transparent;
                        cursor: pointer;
                    }
                }
            }
        }
    }
}

.section-tokenomic {
    padding: 60px 0;

    .main-tokenomic {
        .title {
            font-family: Comic Story;
            font-size: 64px;
            font-weight: 400;
            line-height: 96.7px;
            letter-spacing: 0.06em;
            text-align: center;
            -webkit-text-stroke: 4px #874D11;
            color: #FBE2CD;
            margin-bottom: 40px;
        }

        .box-img {
            img {
                width: 100%;
            }
        }
    }
}

.section-chilly-gallery {
    padding: 60px 0;

    .main-chilly-gallery {
        .title {
            font-family: Comic Story;
            font-size: 64px;
            font-weight: 400;
            line-height: 96.7px;
            letter-spacing: 0.06em;
            text-align: center;
            -webkit-text-stroke: 4px #874D11;
            color: #FBE2CD;
            margin-bottom: 40px;
        }

        .columns {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -10px;

            .colum {
                &.w-3 {
                    width: calc(3 / 12* 100%);

                }

                .box-img {
                    padding: 10px;

                    img {
                        width: 100%;
                        display: block;
                    }
                }
            }
        }
    }
}

.section-hows {
    padding: 60px 0;

    .main-hows {
        .title {
            font-family: Comic Story;
            font-size: 64px;
            font-weight: 400;
            line-height: 96.7px;
            letter-spacing: 0.06em;
            text-align: center;
            -webkit-text-stroke: 4px #874D11;
            color: #FBE2CD;
            margin-bottom: 40px;
        }

        .group-hows {
            .item {
                border: 4px solid #874D11;
                padding: 40px 30px;
                border-radius: 20px;
                margin-bottom: 20px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                &:last-child {
                    margin-bottom: 0;
                }

                .left {
                    width: 10%;

                    .number {
                        font-family: Comic Story;
                        font-size: 64px;
                        font-weight: 400;
                        line-height: 96.7px;
                        letter-spacing: 0.06em;
                        text-align: center;
                        -webkit-text-stroke: 4px #874D11;
                        color: #FBE2CD;
                    }
                }

                .right {
                    width: 90%;

                    .text-row {
                        padding-left: 50px;

                        .txt {
                            font-family: Comic Story;
                            font-size: 35.64px;
                            font-weight: 400;
                            line-height: 53.85px;
                            text-align: left;
                            color: #874D11;
                            margin-bottom: 15px;
                        }

                        .desc {
                            font-family: Comic Story;
                            font-size: 24px;
                            font-weight: 400;
                            line-height: 36.26px;
                            text-align: left;
                            color: #874D11;
                        }
                    }
                }
            }
        }
    }
}

.footer {
    padding: 120px 0 40px 0;

    .main-footer {
        .box-img {
            margin-bottom: 150px;

            img {
                width: 500px;
                display: block;
                margin: 0 auto;
            }
        }

        .copy-right {
            font-family: Comic Story;
            font-size: 36px;
            font-weight: 400;
            line-height: 54.4px;
            text-align: center;
            color: #874D11;

        }
    }
}

html {
    scroll-behavior: smooth;
}

@media screen and (max-width:768px) {

    .section-banner .main-banner .title {
        font-size: 80px;
        line-height: 1.4;
        padding-top: 40px;
    }

    .section-banner {
        padding: 30px 10px;
    }

    .section-banner .main-banner .desc {
        font-size: 24px;
    }

    .section-banner .main-banner .list-social {
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
    }

    .section-banner .main-banner .list-social .item {
        width: 20%;
    }

    .section-banner .main-banner .list-social .item img {
        width: 100%;
    }

    .section-chilly .main-chilly .left {
        width: 100%;
    }

    .section-chilly .main-chilly .right {
        width: 100%;
    }

    .section-chilly {
        padding: 30px 10px;
    }

    .section-chilly .main-chilly .right .content-chilly .title {
        font-size: 52px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .section-chilly .main-chilly .right .content-chilly .number {
        font-size: 50px;
    }

    .section-chilly .main-chilly .right .content-chilly .number img {
        width: 50px;
    }

    .contract-change .row-contract {
        margin: 30px 0;
        width: -webkit-fill-available;
    }

    .section-bought-chilly .main-bought-chilly .left {
        width: 100%;
    }

    .section-bought-chilly .main-bought-chilly .right {
        width: 100%;
    }

    .section-bought-chilly .main-bought-chilly .right .content-bougth .desc {
        font-size: 24px;
        line-height: 1.4;
    }

    .section-bought-chilly {
        padding: 30px 10px;
    }

    .section-tokenomic {
        padding: 30px 10px;
    }

    .section-tokenomic .main-tokenomic .title {
        font-size: 40px;
    }

    .section-chilly-gallery {
        padding: 30px 10px;
    }

    .section-chilly-gallery .main-chilly-gallery .title {
        font-size: 40px;
    }

    .section-chilly-gallery .main-chilly-gallery .columns .colum.w-3 {
        width: 50%;
    }

    .section-hows {
        padding: 30px 10px;
    }

    .section-hows .main-hows .title {
        font-size: 40px;
    }

    .section-hows .main-hows .group-hows .item .left {
        width: 100%;
    }

    .section-hows .main-hows .group-hows .item .right {
        width: 100%;
    }

    .section-hows .main-hows .group-hows .item .right .text-row {
        padding-left: 0;
        text-align: center;
    }

    .section-hows .main-hows .group-hows .item .right .text-row .txt {
        font-size: 24px;
        text-align: center;
        line-height: 1.4;
    }

    .section-hows .main-hows .group-hows .item .right .text-row .desc {
        text-align: center;
        font-size: 18px;
        line-height: 1.4;
    }

    .section-hows .main-hows .group-hows .item .left .number {
        font-size: 50px;
    }

    .section-hows .main-hows .group-hows .item {
        padding: 20px;
    }

    .footer .main-footer .box-img img {
        width: 100%;
    }

    .footer .main-footer .box-img {
        margin-bottom: 50px;
    }

    .footer .main-footer .copy-right {
        font-size: 16px;
    }
}

.snowflake img {
    width: 60px;
}

@-webkit-keyframes snowflakes-fall {
    0% {
        top: -10%
    }

    100% {
        top: 100%
    }
}

@-webkit-keyframes snowflakes-shake {

    0%,
    100% {
        -webkit-transform: translateX(0);
        transform: translateX(0)
    }

    50% {
        -webkit-transform: translateX(80px);
        transform: translateX(80px)
    }
}

@keyframes snowflakes-fall {
    0% {
        top: -10%
    }

    100% {
        top: 100%
    }
}

@keyframes snowflakes-shake {

    0%,
    100% {
        transform: translateX(0)
    }

    50% {
        transform: translateX(80px)
    }
}

.snowflake {
    color: #fff;
    font-size: 1em;
    font-family: Arial, sans-serif;
    text-shadow: 0 0 5px #000;
    position: fixed;
    top: -10%;
    z-index: 9999;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: default;
    -webkit-animation-name: snowflakes-fall, snowflakes-shake;
    -webkit-animation-duration: 5s, 2s;
    -webkit-animation-timing-function: linear, ease-in-out;
    -webkit-animation-iteration-count:infinite,infinite;
    -webkit-animation-play-state: running, running;
    animation-name: snowflakes-fall, snowflakes-shake;
    animation-duration: 5s, 2s;
    animation-timing-function: linear, ease-in-out;
    animation-iteration-count:infinite,infinite;
    animation-play-state: running, running;
}

.snowflake:nth-of-type(0) {
    left: 1%;
    -webkit-animation-delay: 0s, 0s;
    animation-delay: 0s, 0s
}

.snowflake:nth-of-type(1) {
    left: 10%;
    -webkit-animation-delay: 1s, 1s;
    animation-delay: 1s, 1s
}

.snowflake:nth-of-type(2) {
    left: 20%;
    -webkit-animation-delay: 6s, .5s;
    animation-delay: 6s, .5s
}

.snowflake:nth-of-type(3) {
    left: 30%;
    -webkit-animation-delay: 4s, 2s;
    animation-delay: 4s, 2s
}

.snowflake:nth-of-type(4) {
    left: 40%;
    -webkit-animation-delay: 2s, 2s;
    animation-delay: 2s, 2s
}

.snowflake:nth-of-type(5) {
    left: 50%;
    -webkit-animation-delay: 8s, 3s;
    animation-delay: 8s, 3s
}

.snowflake:nth-of-type(6) {
    left: 60%;
    -webkit-animation-delay: 6s, 2s;
    animation-delay: 6s, 2s
}

.snowflake:nth-of-type(7) {
    left: 70%;
    -webkit-animation-delay: 2.5s, 1s;
    animation-delay: 2.5s, 1s
}

.snowflake:nth-of-type(8) {
    left: 80%;
    -webkit-animation-delay: 1s, 0s;
    animation-delay: 1s, 0s
}

.snowflake:nth-of-type(9) {
    left: 90%;
    -webkit-animation-delay: 3s, 1.5s;
    animation-delay: 3s, 1.5s
}

.snowflake:nth-of-type(10) {
    left: 25%;
    -webkit-animation-delay: 2s, 0s;
    animation-delay: 2s, 0s
}

.snowflake:nth-of-type(11) {
    left: 65%;
    -webkit-animation-delay: 4s, 2.5s;
    animation-delay: 4s, 2.5s
}